.DateRangePickerInput {
  // max-width: 230px;
  height: 38px;
  // background: linear-gradient(180deg, #ffffff 0%, #f8f9ff 100%);
  border: 1px solid #dbe3ef;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.06);
  border-radius: 6px;
  padding-left: 5px;
  span {
    color: #4a5677 !important;
  }

  input {
    // width: 100%;
    // height: 100%;
    border: none;

    &:focus {
      outline: none;
    }

    font-weight: 500;
    color: var(--remaining-color);
  }

  &:hover {
    background: linear-gradient(180deg, #ffffff 0%, #fcfcff 100%);
  }

  &:active {
    background: linear-gradient(180deg, #f8f9ff 0%, #f1f2f5 100%);
  }
}
@media (max-width: 1280px) {
  .DateRangePickerInput {
    min-width: 120px;
  }
}
@media (max-width: 751px) {
  .DateRangePickerInput {
    min-width: 158px;
    margin-right: 6px;
  }
}
.ranges {
  overflow: auto;
  ul li {
    &:hover {
      background-color: #f3f6f9 !important;
    }
    &:active {
      background-color: #f3f6f9 !important;
    }
    &:focus {
      background-color: #f3f6f9 !important;
    }
  }
}
// .daterangepicker .ranges li:hover,
// .daterangepicker .ranges li.active {
//   color: #7e8299 !important;
//   background: #fff !important;
// }
