.header-mobile .btn > .svg-icon {
  filter: invert(100%) sepia(0%) saturate(1%) hue-rotate(74deg) brightness(102%) contrast(101%);

  // & > img {
  //     filter: invert(100%) sepia(0%) saturate(1%) hue-rotate(74deg) brightness(102%) contrast(101%);
  // }

  // &:hover > img {
  //     filter: invert(59%) sepia(68%) saturate(3862%) hue-rotate(189deg) brightness(100%) contrast(102%);
  // }
}

.header-mobile .btn.active {
  filter: invert(59%) sepia(68%) saturate(3862%) hue-rotate(189deg) brightness(100%) contrast(102%);
}

.header-mobile {
  background-color: #ff7b00;
}
