@media (min-width: 992px) {
  .brand {
    background-color: #242939;
    -webkit-box-shadow: none;
    box-shadow: none; }
    .brand .btn i {
      color: rgba(255, 255, 255, 0.65); }
    .brand .btn .svg-icon svg g [fill] {
      -webkit-transition: fill 0.3s ease;
      transition: fill 0.3s ease;
      fill: rgba(255, 255, 255, 0.65); }
    .brand .btn .svg-icon svg:hover g [fill] {
      -webkit-transition: fill 0.3s ease;
      transition: fill 0.3s ease; }
    .brand .btn.active .svg-icon svg g [fill], .brand .btn:hover .svg-icon svg g [fill] {
      -webkit-transition: fill 0.3s ease;
      transition: fill 0.3s ease;
      fill: #3699ff; }
    .brand .btn.active .svg-icon svg:hover g [fill], .brand .btn:hover .svg-icon svg:hover g [fill] {
      -webkit-transition: fill 0.3s ease;
      transition: fill 0.3s ease; }
    .brand .btn.active i, .brand .btn:hover i {
      color: #3699ff; } }

@media (max-width: 991.98px) {
  .header-mobile {
    background-color: #242939;
    -webkit-box-shadow: 0px 1px 9px -3px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 1px 9px -3px rgba(0, 0, 0, 0.1); }
    .header-mobile .burger-icon span {
      background-color: #484f66; }
      .header-mobile .burger-icon span::before, .header-mobile .burger-icon span::after {
        background-color: #484f66; }
    .header-mobile .burger-icon:hover span {
      background-color: #3699ff; }
      .header-mobile .burger-icon:hover span::before, .header-mobile .burger-icon:hover span::after {
        background-color: #3699ff; }
    .header-mobile .burger-icon-active span {
      background-color: #3699ff; }
      .header-mobile .burger-icon-active span::before, .header-mobile .burger-icon-active span::after {
        background-color: #3699ff; }
    .header-mobile .btn i {
      color: #484f66; }
    .header-mobile .btn .svg-icon svg g [fill] {
      -webkit-transition: fill 0.3s ease;
      transition: fill 0.3s ease;
      fill: #484f66; }
    .header-mobile .btn .svg-icon svg:hover g [fill] {
      -webkit-transition: fill 0.3s ease;
      transition: fill 0.3s ease; }
    .header-mobile .btn.active .svg-icon svg g [fill], .header-mobile .btn:hover .svg-icon svg g [fill] {
      -webkit-transition: fill 0.3s ease;
      transition: fill 0.3s ease;
      fill: #3699ff; }
    .header-mobile .btn.active .svg-icon svg:hover g [fill], .header-mobile .btn:hover .svg-icon svg:hover g [fill] {
      -webkit-transition: fill 0.3s ease;
      transition: fill 0.3s ease; }
    .header-mobile .btn.active i, .header-mobile .btn:hover i {
      color: #3699ff; } }
